import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userStepGachaGroupFilters = [
  <NumberInput source="StepGachaGroupId" label="StepGachaGroupId" />,
  <NumberInput source="LoopCount" label="ループした回数" />,
  <NumberInput source="Step" label="現在のステップ" />,
];

export const UserStepGachaGroupList = (props: any) => (
  <CommonList {...props} addFilters={userStepGachaGroupFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="StepGachaGroupId" label="StepGachaGroupId" />
      <NumberField source="LoopCount" label="ループした回数" />
      <NumberField source="Step" label="現在のステップ" />
    </Datagrid>
  </CommonList>
);
