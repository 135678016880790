import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";

export const ResultCard = (props: any) => {
  return (
    <Card>
      <CardContent>{JSON.stringify(props.data)}</CardContent>
    </Card>
  );
};
