import {
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  FileField,
  FileInput,
  useDataProvider,
  useNotify,
  Toolbar,
  SaveButton,
} from "react-admin";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { FileInputResult, readFileAsText } from "utils/file";
import { CommonList } from "../Common";

export const BlockWordList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="word" label="word" />
    </Datagrid>
  </CommonList>
);

const BlockWordCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const fileData: FileInputResult = getValues("attachments");
      const fileContents = await readFileAsText(fileData.rawFile);
      const lines = fileContents.split(/\r?\n/);

      return dataProvider.create("BlockWord", {
        data: {
          words: lines,
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

export const BlockWordCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm toolbar={<BlockWordCreateToolbar />}>
      <FileInput source="attachments">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
