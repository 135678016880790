import { Datagrid, NumberField, TextField, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userActStartTimeFilters = [
  <NumberInput source="SlotNumber" label="スロットNO" />,
  <NumberInput source="ActLevel" label="ACTレベル" />,
];

export const UserActStartTimeList = (props: any) => (
  <CommonList {...props} addFilters={userActStartTimeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="SlotNumber" label="スロットNO" />
      <YMDHMSDateField source="StartedAt" label="開始日時" />
      <NumberField source="ActLevel" label="ACTレベル" />
    </Datagrid>
  </CommonList>
);
