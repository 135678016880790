import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userGachaStampFilters = [
  <NumberInput source="GachaStampId" label="GachaStampId" />,
  <NumberInput source="Step" label="ステップ数" />,
];

export const UserGachaStampList = (props: any) => (
  <CommonList {...props} addFilters={userGachaStampFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="GachaStampId" label="GachaStampId" />
      <NumberField source="Step" label="ステップ数" />
    </Datagrid>
  </CommonList>
);
