import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userCharacterTopFilters = [
  <NumberInput source="CharacterId" label="CharacterId" />,
];

export const UserCharacterTopList = (props: any) => (
  <CommonList {...props} addFilters={userCharacterTopFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="CharacterId" label="CharacterId" />
    </Datagrid>
  </CommonList>
);
