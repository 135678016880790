import { Datagrid, NumberField, TextField, NumberInput } from "react-admin";
import { CommonList } from "../Common";

const userActCharacterFilters = [
  <NumberInput source="SlotNumber" label="スロットNO" />,
  <NumberInput source="CharacterType" label="CharacterType" />,
];

export const UserActCharacterList = (props: any) => (
  <CommonList {...props} addFilters={userActCharacterFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="SlotNumber" label="スロットNO" />
      <NumberField source="CharacterType" label="CharacterType" />
    </Datagrid>
  </CommonList>
);
