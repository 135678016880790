import {
  BooleanField,
  BooleanInput,
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
} from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";

const logUserGachaContentFilters = [
  <NumberInput source="PurchaseNumber" label="NO" />,
  <NumberInput source="Count" label="回数" />,
  <NumberInput source="GachaId" label="GachaId" />,
  <NumberInput source="GachaContentId" label="GachaContentId" />,
  <NumberInput source="CardType" label="カードタイプ" />,
  <NumberInput source="CardId" label="カードID" />,
  <BooleanInput source="IsConverted" label="ピース変換" />,
  <DateTimeWithSecInput source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput source="CreatedAt_to" label="作成日時To" />,
];

export const LogUserGachaContentList = (props: any) => (
  <CommonList {...props} addFilters={logUserGachaContentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="PurchaseNumber" label="NO" />
      <NumberField source="Count" label="回数" />
      <NumberField source="GachaId" label="GachaId" />
      <NumberField source="GachaContentId" label="GachaContentId" />
      <NumberField source="CardType" label="カードタイプ" />
      <NumberField source="CardId" label="カードID" />
      <BooleanField source="IsConverted" label="ピース変換" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
