import { Datagrid, NumberField, NumberInput, TextField } from "react-admin";
import { CommonList } from "../Common";

const userLastPartyNumberFilters = [
  <NumberInput source="PartyNumber" label="パーティーNO" />,
];

export const UserLastPartyNumberList = (props: any) => (
  <CommonList {...props} addFilters={userLastPartyNumberFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="PartyNumber" label="パーティーNO" />
    </Datagrid>
  </CommonList>
);
