import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userBoxGachaGroupPrizeFilters = [
  <NumberInput source="BoxGachaGroupId" label="BoxGachaGroupId" />,
  <NumberInput source="BoxGachaPrizeId" label="BoxGachaPrizeId" />,
  <NumberInput source="Num" label="獲得数" />,
];

export const UserBoxGachaGroupPrizeList = (props: any) => (
  <CommonList {...props} addFilters={userBoxGachaGroupPrizeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="BoxGachaGroupId" label="BoxGachaGroupId" />
      <NumberField source="BoxGachaPrizeId" label="BoxGachaPrizeId" />
      <NumberField source="Num" label="獲得数" />
    </Datagrid>
  </CommonList>
);
