import {
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
} from "react-admin";
import { CommonList } from "../Common";

const userStaminaFilters = [
  <NumberInput source="Stamina" label="AP" />,
  <TextInput source="LastUpdatedAt" label="最終更新日時" />,
];

export const UserStaminaList = (props: any) => (
  <CommonList {...props} addFilters={userStaminaFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="Stamina" label="AP" />
      <TextField source="LastUpdatedAt" label="最終更新日時" />
    </Datagrid>
  </CommonList>
);
