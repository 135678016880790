import { Datagrid, NumberField, NumberInput, TextField } from "react-admin";
import { CommonList } from "../Common";

const userBattlePassSeasonFilters = [
  <NumberInput source="BattlePassSeasonId" label="BattlePassSeasonId" />,
  <NumberInput source="Level" label="レベル" />,
  <NumberInput source="Exp" label="EXP" />,
];

export const UserBattlePassSeasonList = (props: any) => (
  <CommonList {...props} addFilters={userBattlePassSeasonFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="BattlePassSeasonId" label="BattlePassSeasonId" />
      <NumberField source="Level" label="レベル" />
      <NumberField source="Exp" label="EXP" />
    </Datagrid>
  </CommonList>
);
