import {
  Datagrid,
  TextField,
  TextInput,
  NumberField,
  NumberInput,
} from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const logUserPresentFilters = [
  <TextInput source="PresentId" label="プレゼントID" />,
  <NumberInput source="ContentType" label="ContentType" />,
  <NumberInput source="ContentId" label="ContentId" />,
  <NumberInput source="ContentNum" label="ContentNum" />,
  <TextInput source="AcquisitionFrom" label="付与元" />,
];

export const LogUserPresentList = (props: any) => (
  <CommonList {...props} addFilters={logUserPresentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="PresentId" label="プレゼントID" />
      <NumberField source="ContentType" label="ContentType" />
      <NumberField source="ContentId" label="ContentId" />
      <NumberField source="ContentNum" label="ContentNum" />
      <TextField source="AcquisitionFrom" label="付与元" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
