import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userHonorMissionFilters = [
  <NumberInput source="HonorMissionId" label="HonorMissionId" />,
  <NumberInput source="Progress" label="進捗" />,
];

export const UserHonorMissionList = (props: any) => (
  <CommonList {...props} addFilters={userHonorMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="HonorMissionId" label="HonorMissionId" />
      <NumberField source="Progress" label="進捗" />
    </Datagrid>
  </CommonList>
);
