import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userQuestBattleMissionFilters = [
  <NumberInput source="QuestBattleMissionId" label="QuestBattleMissionId" />,
];

export const UserQuestBattleMissionList = (props: any) => (
  <CommonList {...props} addFilters={userQuestBattleMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="QuestBattleMissionId" label="QuestBattleMissionId" />
    </Datagrid>
  </CommonList>
);
