import { CommonList, YMDHMSDateField } from "components/Common";
import {
  Datagrid,
  TextField,
  NumberInput,
  NumberField,
  BooleanField,
} from "react-admin";

const userTutorialProgressFilters = [
  <NumberInput source="Progress" label="進捗" />,
];

export const UserTutorialProgressList = (props: any) => (
  <CommonList {...props} addFilters={userTutorialProgressFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="Progress" label="進捗" />
      <BooleanField source="IsGachaExecuted" label="ガチャ実行" />
      <YMDHMSDateField source="CompletedAt" label="完了日時" />
    </Datagrid>
  </CommonList>
);
