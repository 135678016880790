import {
  BooleanField,
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";

const userEquipmentCardFilters = [
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
  <NumberInput source="UserEquipmentCardNumber" label="NO" />,
  <NumberInput source="Rank" label="覚醒段階" />,
  <NumberInput source="Exp" label="EXP" />,
  <BooleanInput source="IsFavorite" label="お気に入り" />,
  <DateTimeWithSecInput source="AcquiredAt_from" label="獲得日時From" />,
  <DateTimeWithSecInput source="AcquiredAt_to" label="獲得日時To" />,
];

export const UserEquipmentCardList = (props: any) => (
  <CommonList {...props} addFilters={userEquipmentCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="EquipmentCardId" label="EquipmentCardId" />
      <ReferenceField
        source="EquipmentCardId"
        reference="EquipmentCard"
        label="メモリーカード名"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="UserEquipmentCardNumber" label="NO" />
      <NumberField source="Rank" label="覚醒段階" />
      <NumberField source="Exp" label="EXP" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <YMDHMSDateField source="AcquiredAt" label="獲得日時" />
    </Datagrid>
  </CommonList>
);
