import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userNormalLoginBonusFilters = [
  <NumberInput source="ReceivedCount" label="受取回数" />,
];

export const UserNormalLoginBonusList = (props: any) => (
  <CommonList {...props} addFilters={userNormalLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="ReceivedCount" label="受取回数" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
