import { Datagrid, NumberField, NumberInput, TextField } from "react-admin";
import { CommonList } from "../Common";

const userPartyEquipmentCardFilters = [
  <NumberInput source="PartyNumber" label="パーティーNO" />,
  <NumberInput source="CharacterCardSlotNumber" label="MCカードスロットNO" />,
  <NumberInput
    source="EquipmentCardSlotNumber"
    label="メモリーカードスロットNO"
  />,
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
  <NumberInput source="UserEquipmentCardNumber" label="ユーザーMCカードNO" />,
];

export const UserPartyEquipmentCardList = (props: any) => (
  <CommonList {...props} addFilters={userPartyEquipmentCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="PartyNumber" label="パーティーNO" />
      <NumberField
        source="CharacterCardSlotNumber"
        label="MCカードスロットNO"
      />
      <NumberField
        source="EquipmentCardSlotNumber"
        label="メモリーカードスロットNO"
      />
      <NumberField source="EquipmentCardId" label="EquipmentCardId" />
      <NumberField
        source="UserEquipmentCardNumber"
        label="ユーザーMCカードNO"
      />
    </Datagrid>
  </CommonList>
);
