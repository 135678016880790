import {
  Datagrid,
  TextField,
  NumberInput,
  NumberField,
  BooleanField,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { CommonList } from "../Common";

const userQuestBattleDifficultyFilters = [
  <NumberInput
    source="QuestBattleDifficultyId"
    label="QuestBattleDifficultyId"
  />,
  <BooleanInput source="IsPlayed" label="プレイ済み" />,
  <BooleanInput source="IsCleared" label="クリア済み" />,
];

export const UserQuestBattleDifficultyList = (props: any) => (
  <CommonList {...props} addFilters={userQuestBattleDifficultyFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField
        source="QuestBattleDifficultyId"
        label="QuestBattleDifficultyId"
      />
      <ReferenceField
        source="QuestBattleDifficultyId"
        reference="QuestBattleDifficulty"
        label="クエスト名"
      >
        <TextField source="title" />
      </ReferenceField>
      <BooleanField source="IsPlayed" label="プレイ済み" />
      <BooleanField source="IsCleared" label="クリア済み" />
    </Datagrid>
  </CommonList>
);
