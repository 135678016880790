import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userEquipmentCardAcquisitionFilters = [
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
];

export const UserEquipmentCardAcquisitionList = (props: any) => (
  <CommonList {...props} addFilters={userEquipmentCardAcquisitionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="EquipmentCardId" label="EquipmentCardId" />
    </Datagrid>
  </CommonList>
);
