import {
  Datagrid,
  TextField,
  TextInput,
  NumberInput,
  NumberField,
} from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userBattleStatusFilters = [
  <NumberInput
    source="QuestBattleDifficultyId"
    label="QuestBattleDifficultyId"
  />,
  <NumberInput source="EnemyGroupId_1" label="EnemyGroupId_1" />,
  <NumberInput source="EnemyGroupId_2" label="EnemyGroupId_2" />,
  <NumberInput source="EnemyGroupId_3" label="EnemyGroupId_3" />,
  <NumberInput source="ContinueCount" label="コンティニュー回数" />,
  <TextInput source="Status" label="ステータス" />,
  <NumberInput source="PartyNumber" label="パーティNO" />,
];

export const UserBattleStatusList = (props: any) => (
  <CommonList {...props} addFilters={userBattleStatusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField
        source="QuestBattleDifficultyId"
        label="QuestBattleDifficultyId"
      />
      <NumberField source="EnemyGroupId_1" label="EnemyGroupId_1" />
      <NumberField source="EnemyGroupId_2" label="EnemyGroupId_2" />
      <NumberField source="EnemyGroupId_3" label="EnemyGroupId_3" />
      <NumberField source="ContinueCount" label="コンティニュー回数" />
      <TextField source="Status" label="ステータス" />
      <NumberField source="PartyNumber" label="パーティNO" />
      <YMDHMSDateField source="BattleStartedAt" label="バトル開始日" />
      <YMDHMSDateField source="BattleExpiredAt" label="バトル有効期限" />
    </Datagrid>
  </CommonList>
);
