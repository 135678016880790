import { List, TextInput } from "react-admin";
import { CommonPagination, CommonActions } from "./index";

export const CommonList = (props: any) => {
  let filters = [
    <TextInput
      source="UserId"
      label="ユーザーID"
      alwaysOn
      sx={{ width: "350px" }}
    />,
  ];

  if (props.filters === null) {
    filters = [];
  } else if (props.filters) {
    filters = props.filters;
  } else if (props.addFilters) {
    filters.push(...props.addFilters);
  }

  let sort = { field: "UserId", order: "ASC" };
  if (props.sort) {
    sort = props.sort;
  }

  return (
    <List
      {...props}
      actions={<CommonActions />}
      empty={false}
      perPage={100}
      pagination={<CommonPagination />}
      sort={sort}
      filters={filters}
    />
  );
};
