import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userBilledStoneFilters = [<NumberInput source="Count" label="個数" />];

export const UserBilledStoneList = (props: any) => (
  <CommonList {...props} addFilters={userBilledStoneFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="Count" label="個数" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
