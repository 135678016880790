import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList } from "../Common";

const userStartedMissionGroupFilters = [
  <NumberInput source="MissionGroupId" label="MissionGroupId" />,
];

export const UserStartedMissionGroupList = (props: any) => (
  <CommonList {...props} addFilters={userStartedMissionGroupFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="MissionGroupId" label="MissionGroupId" />
    </Datagrid>
  </CommonList>
);
