import { CommonList, YMDHMSDateField } from "components/Common";
import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";

const userQuestPlayLimitFilters = [
  <NumberInput source="QuestPlayLimitId" label="QuestPlayLimitId" />,
  <NumberInput source="PlayCount" label="プレイ回数" />,
  <NumberInput source="RecoveryCount" label="回復回数" />,
];

export const UserQuestPlayLimitList = (props: any) => (
  <CommonList {...props} addFilters={userQuestPlayLimitFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="QuestPlayLimitId" label="QuestPlayLimitId" />
      <NumberField source="PlayCount" label="プレイ回数" />
      <NumberField source="RecoveryCount" label="回復回数" />
      <YMDHMSDateField source="LastPlayedAt" label="最終プレイ日時" />
    </Datagrid>
  </CommonList>
);
