import { Datagrid, TextField, NumberInput, NumberField } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userEventLoginBonusFilters = [
  <NumberInput source="SeasonId" label="SeasonId" />,
  <NumberInput source="ReceivedCount" label="受取回数" />,
];

export const UserEventLoginBonusList = (props: any) => (
  <CommonList {...props} addFilters={userEventLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="SeasonId" label="SeasonId" />
      <NumberField source="ReceivedCount" label="受取回数" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
